import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "tab", "tabPanel", "tabPanelIndex" ]
  static values = { index: Number, activeAddClass: String, activeRemoveClass: String }

  change(e) {
    this.indexValue = this.tabTargets.indexOf(e.currentTarget)
    e.preventDefault()
    e.stopPropagation()
  }

  showCurrentTab() {

    this.tabPanelTargets.forEach((el, i) => {

      if (i == this.indexValue) {
        el.classList.remove('hidden')
      } else {
        el.classList.add('hidden')
      }

    })

    this.tabPanelIndexTargets.forEach((el, i) => {
      console.log(el.dataset.tabIndex)

      if (el.dataset.tabIndex == this.indexValue){
        el.classList.remove('hidden')
      } else {
        el.classList.add('hidden')
      }
    })

    this.tabTargets.forEach((el, i) => {
      console.log(el.parentNode)
      console.log(i)
      console.log(this.indexValue)
      if(i==this.indexValue){
        this.activeAddClassValue.split(" ").forEach((cls) => el.parentNode.classList.add(cls))
        this.activeRemoveClassValue.split(" ").forEach((cls) => el.parentNode.classList.remove(cls))
        console.log("ACTIVE")
      } else {
        this.activeAddClassValue.split(" ").forEach((cls) => el.parentNode.classList.remove(cls))
        this.activeRemoveClassValue.split(" ").forEach((cls) => el.parentNode.classList.add(cls))
      }
    })
  }

  indexValueChanged() {
    this.showCurrentTab()
  }

}
